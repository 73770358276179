import { State } from '@/config/state';
import type { Shop } from '@/types/shops';

const useShopData = () => {
  const shopData = useState<Shop>(State.ShopData);
  const GqlInstance = useGql();

  const setShopData = async (slug: string, locale: string, getDataCached: boolean = true) => {
    try {
      shopData.value = (await GqlInstance('Shop', {
        getDataCached,
        locale,
        slug,
      })).shop;
    } catch (error) {
      throw new Error(`Shop not found on slug: "${slug}"`);
    }
  };

  const getShopData = computed(() => shopData.value);

  return {
    getShopData,
    setShopData,
  };
};

export { useShopData };
