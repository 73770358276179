<script setup lang="ts">
import { MIN_NUMBER_OF_IMPRESSIONS } from '@/config';

const INITIAL_TEXT_HEIGHT = 54;

const CLASSES = {
  COLUMN_RIGHT: 'column-right md:col-[2]',
  FIRST_ROW: 'first-row md:row-[1]',
  FULL_HEIGHT: 'full-height md:row-[1_/_span_2]',
  FULL_WIDTH: 'full-width md:col-[1_/_span_2]',
  SECOND_ROW: 'second-row md:row-[2]',
};

const { getShopData } = useShopData();

const hasAdditionalInformation = computed(() => (getShopData.value?.quote
  || getShopData.value?.impressions?.length
  || getShopData.value?.description));

const textWithQuotes = computed(() => `"${getShopData.value?.quote}"`);

const hasQuoteAndDescription = computed(() => (getShopData.value?.quote
  && getShopData.value?.description));

const hasShopCover = computed(() => !!getShopData.value?.cover);

const shouldShowImpressions = computed(() => getShopData.value?.impressions
  && getShopData.value?.impressions.length >= MIN_NUMBER_OF_IMPRESSIONS);

const shopInformationBasicWrapperClasses = computed(() => ({
  [CLASSES.FIRST_ROW]: shouldShowImpressions.value,
  [CLASSES.FULL_HEIGHT]: !shouldShowImpressions.value && hasQuoteAndDescription.value,
  'shop-information-basic-wrapper-border-bottom md:border-b-0 pb-4 border-b border-b-grey-light': hasAdditionalInformation.value,
}));

const quoteClasses = computed(() => ({
  [CLASSES.COLUMN_RIGHT]: !shouldShowImpressions.value,
  [CLASSES.FIRST_ROW]: hasQuoteAndDescription.value && shouldShowImpressions.value,
  [CLASSES.FULL_WIDTH]: shouldShowImpressions.value && !hasQuoteAndDescription.value,
  [CLASSES.SECOND_ROW]: shouldShowImpressions.value || !hasQuoteAndDescription.value,
}));

const descriptionClasses = computed(() => ({
  [CLASSES.COLUMN_RIGHT]: !shouldShowImpressions.value,
  [CLASSES.FULL_WIDTH]: shouldShowImpressions.value && !hasQuoteAndDescription.value,
  [CLASSES.SECOND_ROW]: shouldShowImpressions.value || hasQuoteAndDescription.value,
}));
</script>

<template>
  <div
    v-if="getShopData"
    class="shop-information flex pb-4 md:relative md:z-30 md:bg-white md:p-5"
    :class="{'mt-8 md:mt-0': !hasShopCover}"
  >
    <div class="shop-information-content m-auto flex w-full max-w-[1050px] flex-col md:grid md:grid-cols-[425px_calc(100%-425px-20px)] md:gap-[15px_20px]">
      <div
        class="shop-information-basic-wrapper px-4 py-0 md:flex md:items-center md:p-0"
        :class="shopInformationBasicWrapperClasses"
      >
        <ShopInformationBasic :shop="getShopData" />
      </div>
      <span
        v-if="getShopData?.quote"
        class="shop-information-quote p-4 pb-0 text-xs font-semibold md:m-0"
        :class="quoteClasses"
      >
        {{ textWithQuotes }}
      </span>
      <ShopImpressions class="shop-information-impressions mt-2.5 md:m-0" />
      <UIReadMoreOrLess
        class="shop-information-description mx-4 mt-3.5 text-xs font-normal md:m-0"
        :class="descriptionClasses"
        :initial-height="INITIAL_TEXT_HEIGHT"
      >
        <span class="text-xs">{{ getShopData.description }}</span>
      </UIReadMoreOrLess>
    </div>
  </div>
</template>
