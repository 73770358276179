<script setup lang="ts">
import { MIN_NUMBER_OF_IMPRESSIONS } from '@/config';

const SCROLL_DISTANCE = 325;

const { getShopData } = await useShopData();

const shouldShowImpressions = computed(() => (getShopData.value?.impressions
  && getShopData.value.impressions.length >= MIN_NUMBER_OF_IMPRESSIONS));
</script>

<template>
  <div
    v-if="shouldShowImpressions"
    class="shop-impressions flex"
  >
    <UiScroller
      position-button-left="left-[30px]"
      position-button-right="right-[30px]"
      scroll-content-class="scroll-pl-4 md:scroll-pl-0"
      :scroll-distance="SCROLL_DISTANCE"
    >
      <template #scrollerContent>
        <div class="shop-impressions-image-wrapper flex flex-nowrap gap-2.5">
          <NuxtResponsivePicture
            v-for="impression in getShopData?.impressions"
            :key="`${impression.position}-${impression.publicPath}`"
            class="shop-impressions-image aspect-1/1 w-[144px] snap-start overflow-hidden rounded-lg first-of-type:ml-4 last-of-type:mr-4 md:first-of-type:ml-0 md:last-of-type:mr-0"
            provider="cloudflare"
            :src="impression.publicPath ?? undefined"
            :width="144"
          />
        </div>
      </template>
    </UiScroller>
  </div>
</template>
